import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/blog',
    component: ComponentCreator('/blog', '3fd'),
    exact: true
  },
  {
    path: '/blog/archive',
    component: ComponentCreator('/blog/archive', '298'),
    exact: true
  },
  {
    path: '/blog/dockview-1.10.0-release',
    component: ComponentCreator('/blog/dockview-1.10.0-release', 'b48'),
    exact: true
  },
  {
    path: '/blog/dockview-1.10.1-release',
    component: ComponentCreator('/blog/dockview-1.10.1-release', '60f'),
    exact: true
  },
  {
    path: '/blog/dockview-1.10.2-release',
    component: ComponentCreator('/blog/dockview-1.10.2-release', '7a1'),
    exact: true
  },
  {
    path: '/blog/dockview-1.11.0-release',
    component: ComponentCreator('/blog/dockview-1.11.0-release', '176'),
    exact: true
  },
  {
    path: '/blog/dockview-1.12.0-release',
    component: ComponentCreator('/blog/dockview-1.12.0-release', 'b94'),
    exact: true
  },
  {
    path: '/blog/dockview-1.13.0-release',
    component: ComponentCreator('/blog/dockview-1.13.0-release', 'aa4'),
    exact: true
  },
  {
    path: '/blog/dockview-1.13.1-release',
    component: ComponentCreator('/blog/dockview-1.13.1-release', '40e'),
    exact: true
  },
  {
    path: '/blog/dockview-1.14.0-release',
    component: ComponentCreator('/blog/dockview-1.14.0-release', '2e0'),
    exact: true
  },
  {
    path: '/blog/dockview-1.14.1-release',
    component: ComponentCreator('/blog/dockview-1.14.1-release', '3b6'),
    exact: true
  },
  {
    path: '/blog/dockview-1.14.2-release',
    component: ComponentCreator('/blog/dockview-1.14.2-release', '521'),
    exact: true
  },
  {
    path: '/blog/dockview-1.15.0-release',
    component: ComponentCreator('/blog/dockview-1.15.0-release', 'dcb'),
    exact: true
  },
  {
    path: '/blog/dockview-1.15.1-release',
    component: ComponentCreator('/blog/dockview-1.15.1-release', 'bf6'),
    exact: true
  },
  {
    path: '/blog/dockview-1.15.2-release',
    component: ComponentCreator('/blog/dockview-1.15.2-release', '43e'),
    exact: true
  },
  {
    path: '/blog/dockview-1.15.3-release',
    component: ComponentCreator('/blog/dockview-1.15.3-release', 'e35'),
    exact: true
  },
  {
    path: '/blog/dockview-1.16.0-release',
    component: ComponentCreator('/blog/dockview-1.16.0-release', '231'),
    exact: true
  },
  {
    path: '/blog/dockview-1.16.1-release',
    component: ComponentCreator('/blog/dockview-1.16.1-release', 'c90'),
    exact: true
  },
  {
    path: '/blog/dockview-1.17.0-release',
    component: ComponentCreator('/blog/dockview-1.17.0-release', '860'),
    exact: true
  },
  {
    path: '/blog/dockview-1.17.1-release',
    component: ComponentCreator('/blog/dockview-1.17.1-release', 'ad6'),
    exact: true
  },
  {
    path: '/blog/dockview-1.17.2-release',
    component: ComponentCreator('/blog/dockview-1.17.2-release', '66e'),
    exact: true
  },
  {
    path: '/blog/dockview-1.4.1-release',
    component: ComponentCreator('/blog/dockview-1.4.1-release', '1cd'),
    exact: true
  },
  {
    path: '/blog/dockview-1.4.2-release',
    component: ComponentCreator('/blog/dockview-1.4.2-release', '265'),
    exact: true
  },
  {
    path: '/blog/dockview-1.4.3-release',
    component: ComponentCreator('/blog/dockview-1.4.3-release', 'b2b'),
    exact: true
  },
  {
    path: '/blog/dockview-1.5.0-release',
    component: ComponentCreator('/blog/dockview-1.5.0-release', '952'),
    exact: true
  },
  {
    path: '/blog/dockview-1.5.1-release',
    component: ComponentCreator('/blog/dockview-1.5.1-release', '215'),
    exact: true
  },
  {
    path: '/blog/dockview-1.5.2-release',
    component: ComponentCreator('/blog/dockview-1.5.2-release', '2ac'),
    exact: true
  },
  {
    path: '/blog/dockview-1.6.0-release',
    component: ComponentCreator('/blog/dockview-1.6.0-release', '23f'),
    exact: true
  },
  {
    path: '/blog/dockview-1.7.0-release',
    component: ComponentCreator('/blog/dockview-1.7.0-release', 'c3e'),
    exact: true
  },
  {
    path: '/blog/dockview-1.7.1-release',
    component: ComponentCreator('/blog/dockview-1.7.1-release', 'ca6'),
    exact: true
  },
  {
    path: '/blog/dockview-1.7.2-release',
    component: ComponentCreator('/blog/dockview-1.7.2-release', 'e7e'),
    exact: true
  },
  {
    path: '/blog/dockview-1.7.3-release',
    component: ComponentCreator('/blog/dockview-1.7.3-release', '75c'),
    exact: true
  },
  {
    path: '/blog/dockview-1.7.4-release',
    component: ComponentCreator('/blog/dockview-1.7.4-release', '96a'),
    exact: true
  },
  {
    path: '/blog/dockview-1.7.5-release',
    component: ComponentCreator('/blog/dockview-1.7.5-release', '9c9'),
    exact: true
  },
  {
    path: '/blog/dockview-1.7.6-release',
    component: ComponentCreator('/blog/dockview-1.7.6-release', 'c9a'),
    exact: true
  },
  {
    path: '/blog/dockview-1.8.0-release',
    component: ComponentCreator('/blog/dockview-1.8.0-release', '230'),
    exact: true
  },
  {
    path: '/blog/dockview-1.8.2-release',
    component: ComponentCreator('/blog/dockview-1.8.2-release', '117'),
    exact: true
  },
  {
    path: '/blog/dockview-1.8.3-release',
    component: ComponentCreator('/blog/dockview-1.8.3-release', '8f7'),
    exact: true
  },
  {
    path: '/blog/dockview-1.8.4-release',
    component: ComponentCreator('/blog/dockview-1.8.4-release', 'd95'),
    exact: true
  },
  {
    path: '/blog/dockview-1.8.5-release',
    component: ComponentCreator('/blog/dockview-1.8.5-release', 'cb2'),
    exact: true
  },
  {
    path: '/blog/dockview-1.9.0-release',
    component: ComponentCreator('/blog/dockview-1.9.0-release', 'c38'),
    exact: true
  },
  {
    path: '/blog/dockview-1.9.1-release',
    component: ComponentCreator('/blog/dockview-1.9.1-release', 'd7c'),
    exact: true
  },
  {
    path: '/blog/dockview-1.9.2-release',
    component: ComponentCreator('/blog/dockview-1.9.2-release', '42b'),
    exact: true
  },
  {
    path: '/blog/dockview-2.0.0-release',
    component: ComponentCreator('/blog/dockview-2.0.0-release', '29d'),
    exact: true
  },
  {
    path: '/blog/page/2',
    component: ComponentCreator('/blog/page/2', 'c37'),
    exact: true
  },
  {
    path: '/blog/page/3',
    component: ComponentCreator('/blog/page/3', 'ae7'),
    exact: true
  },
  {
    path: '/blog/page/4',
    component: ComponentCreator('/blog/page/4', 'd0c'),
    exact: true
  },
  {
    path: '/blog/page/5',
    component: ComponentCreator('/blog/page/5', '129'),
    exact: true
  },
  {
    path: '/blog/tags',
    component: ComponentCreator('/blog/tags', '9c8'),
    exact: true
  },
  {
    path: '/blog/tags/release',
    component: ComponentCreator('/blog/tags/release', 'aa3'),
    exact: true
  },
  {
    path: '/blog/tags/release/page/2',
    component: ComponentCreator('/blog/tags/release/page/2', 'ed3'),
    exact: true
  },
  {
    path: '/blog/tags/release/page/3',
    component: ComponentCreator('/blog/tags/release/page/3', 'a3a'),
    exact: true
  },
  {
    path: '/blog/tags/release/page/4',
    component: ComponentCreator('/blog/tags/release/page/4', '563'),
    exact: true
  },
  {
    path: '/blog/tags/release/page/5',
    component: ComponentCreator('/blog/tags/release/page/5', 'fce'),
    exact: true
  },
  {
    path: '/demo',
    component: ComponentCreator('/demo', 'e86'),
    exact: true
  },
  {
    path: '/markdown-page',
    component: ComponentCreator('/markdown-page', '02e'),
    exact: true
  },
  {
    path: '/popout',
    component: ComponentCreator('/popout', '38a'),
    exact: true
  },
  {
    path: '/docs',
    component: ComponentCreator('/docs', '6a3'),
    routes: [
      {
        path: '/docs',
        component: ComponentCreator('/docs', '51f'),
        routes: [
          {
            path: '/docs',
            component: ComponentCreator('/docs', '559'),
            routes: [
              {
                path: '/docs/',
                component: ComponentCreator('/docs/', '4f9'),
                exact: true
              },
              {
                path: '/docs/advanced/',
                component: ComponentCreator('/docs/advanced/', '2e3'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/advanced/iframe',
                component: ComponentCreator('/docs/advanced/iframe', '21d'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/advanced/keyboard',
                component: ComponentCreator('/docs/advanced/keyboard', '7dd'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/advanced/nested',
                component: ComponentCreator('/docs/advanced/nested', 'a5d'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/api/dockview/groupApi',
                component: ComponentCreator('/docs/api/dockview/groupApi', '197'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/dockview/options',
                component: ComponentCreator('/docs/api/dockview/options', '417'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/dockview/overview',
                component: ComponentCreator('/docs/api/dockview/overview', '1d8'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/dockview/panelApi',
                component: ComponentCreator('/docs/api/dockview/panelApi', '799'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/gridview/api',
                component: ComponentCreator('/docs/api/gridview/api', '637'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/gridview/options',
                component: ComponentCreator('/docs/api/gridview/options', '77a'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/gridview/panelApi',
                component: ComponentCreator('/docs/api/gridview/panelApi', 'e73'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/paneview/api',
                component: ComponentCreator('/docs/api/paneview/api', 'cb1'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/paneview/options',
                component: ComponentCreator('/docs/api/paneview/options', '805'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/paneview/panelApi',
                component: ComponentCreator('/docs/api/paneview/panelApi', '09d'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/splitview/api',
                component: ComponentCreator('/docs/api/splitview/api', '8ca'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/splitview/options',
                component: ComponentCreator('/docs/api/splitview/options', '20e'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/api/splitview/panelApi',
                component: ComponentCreator('/docs/api/splitview/panelApi', 'a41'),
                exact: true,
                sidebar: "api"
              },
              {
                path: '/docs/core/dnd/disable',
                component: ComponentCreator('/docs/core/dnd/disable', '894'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/dnd/dragAndDrop',
                component: ComponentCreator('/docs/core/dnd/dragAndDrop', '8ae'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/dnd/external',
                component: ComponentCreator('/docs/core/dnd/external', 'a34'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/dnd/overview',
                component: ComponentCreator('/docs/core/dnd/overview', '89d'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/dnd/thirdParty',
                component: ComponentCreator('/docs/core/dnd/thirdParty', 'dfd'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/groups/constraints',
                component: ComponentCreator('/docs/core/groups/constraints', '27b'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/groups/controls',
                component: ComponentCreator('/docs/core/groups/controls', '10f'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/groups/floatingGroups',
                component: ComponentCreator('/docs/core/groups/floatingGroups', 'a0d'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/groups/hiddenHeader',
                component: ComponentCreator('/docs/core/groups/hiddenHeader', '2bb'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/groups/locked',
                component: ComponentCreator('/docs/core/groups/locked', '034'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/groups/maxmizedGroups',
                component: ComponentCreator('/docs/core/groups/maxmizedGroups', 'b02'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/groups/move',
                component: ComponentCreator('/docs/core/groups/move', 'a05'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/groups/popoutGroups',
                component: ComponentCreator('/docs/core/groups/popoutGroups', 'f61'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/groups/resizing',
                component: ComponentCreator('/docs/core/groups/resizing', '3fd'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/locked',
                component: ComponentCreator('/docs/core/locked', 'ad5'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/overview',
                component: ComponentCreator('/docs/core/overview', '169'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/panels/add',
                component: ComponentCreator('/docs/core/panels/add', '9c5'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/panels/move',
                component: ComponentCreator('/docs/core/panels/move', '64f'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/panels/register',
                component: ComponentCreator('/docs/core/panels/register', 'df8'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/panels/remove',
                component: ComponentCreator('/docs/core/panels/remove', 'ce2'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/panels/rendering',
                component: ComponentCreator('/docs/core/panels/rendering', '5ee'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/panels/resizing',
                component: ComponentCreator('/docs/core/panels/resizing', '40f'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/panels/tabs',
                component: ComponentCreator('/docs/core/panels/tabs', '999'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/panels/update',
                component: ComponentCreator('/docs/core/panels/update', 'fd0'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/scrollbars',
                component: ComponentCreator('/docs/core/scrollbars', '481'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/state/load',
                component: ComponentCreator('/docs/core/state/load', '323'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/state/save',
                component: ComponentCreator('/docs/core/state/save', '94e'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core/watermark',
                component: ComponentCreator('/docs/core/watermark', '8eb'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/other/gridview/overview',
                component: ComponentCreator('/docs/other/gridview/overview', '4bd'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/other/paneview/overview',
                component: ComponentCreator('/docs/other/paneview/overview', '252'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/other/splitview/overview',
                component: ComponentCreator('/docs/other/splitview/overview', '33f'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/other/tabview',
                component: ComponentCreator('/docs/other/tabview', 'ae5'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/overview/getStarted/contributing',
                component: ComponentCreator('/docs/overview/getStarted/contributing', '77a'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/overview/getStarted/installation',
                component: ComponentCreator('/docs/overview/getStarted/installation', '183'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/overview/getStarted/theme',
                component: ComponentCreator('/docs/overview/getStarted/theme', 'd55'),
                exact: true,
                sidebar: "tutorialSidebar"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: ComponentCreator('/', '3b1'),
    exact: true
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
